<script>
	// Packages
	import router from "page";
	import { SvelteToast } from "@zerodevx/svelte-toast";

	// Stores
	import { routing } from "./stores";

	// Pages
	import Home from "./pages/Home.svelte";
	import Videos from "./pages/Videos.svelte";
	import About from "./pages/About.svelte";
	import Contact from "./pages/Contact.svelte";

	// Components
	import Navbar from "./components/navbar/Navbar.svelte";
	import Footer from "./components/footer/Footer.svelte";

	router($routing.routes.home, () => ($routing.page = Home));
	router($routing.routes.videos, () => ($routing.page = Videos));
	router($routing.routes.about, () => ($routing.page = About));
	router($routing.routes.contact, () => ($routing.page = Contact));
	router($routing.routes.default, () => ($routing.page = Home));

	router.start();
</script>

<div
	style="min-width: 320px;"
	class="flex flex-col min-h-screen mx-auto bg-primary scrollbar-thin scrollbar-thumb-accent hover:scrollbar-thumb-accent-focus scrollbar-track-primary-focus text-white"
>
	<Navbar />

	<!-- Renders the page -->
	<main
		style="margin-top: 80px;"
		class="flex-grow container overflow-hidden"
		id="top"
	>
		<SvelteToast />
		<svelte:component this={$routing.page} />
	</main>

	<Footer />
</div>

<style global lang="postcss">
	@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:wght@200;300;400;500;600;700&display=swap");

	html {
		scroll-behavior: smooth;
	}

	@tailwind base;
	@tailwind components;
	@tailwind utilities;
</style>
