<script>
	export let className = "";
</script>

<svg class={className} viewBox="0 0 17 17" fill="none">
	<path
		d="M4.65786 0.741768C4.81236 0.89646 4.89914 1.10615 4.89914 1.32478C4.89914 1.54341 4.81236 1.75311 4.65786 1.9078L3.31583 3.24983H10.125C11.4848 3.24983 12.814 3.65305 13.9446 4.4085C15.0753 5.16396 15.9565 6.23771 16.4768 7.49399C16.9972 8.75026 17.1333 10.1326 16.8681 11.4663C16.6028 12.7999 15.948 14.025 14.9865 14.9865C14.025 15.948 12.7999 16.6028 11.4663 16.8681C10.1326 17.1333 8.75026 16.9972 7.49399 16.4768C6.23771 15.9565 5.16396 15.0753 4.4085 13.9446C3.65305 12.814 3.24983 11.4848 3.24983 10.125C3.24983 9.90619 3.33675 9.69634 3.49147 9.54162C3.64619 9.3869 3.85604 9.29998 4.07485 9.29998C4.29366 9.29998 4.50351 9.3869 4.65823 9.54162C4.81295 9.69634 4.89987 9.90619 4.89987 10.125C4.89987 11.1584 5.20632 12.1687 5.78046 13.0279C6.35461 13.8872 7.17066 14.5569 8.12543 14.9524C9.0802 15.3479 10.1308 15.4513 11.1444 15.2497C12.1579 15.0481 13.089 14.5505 13.8197 13.8197C14.5505 13.089 15.0481 12.1579 15.2497 11.1444C15.4513 10.1308 15.3479 9.0802 14.9524 8.12543C14.5569 7.17066 13.8872 6.35461 13.0279 5.78046C12.1687 5.20632 11.1584 4.89987 10.125 4.89987H3.31583L4.65786 6.2419C4.8036 6.3983 4.88293 6.60516 4.87916 6.81889C4.87539 7.03263 4.78881 7.23656 4.63765 7.38771C4.48649 7.53887 4.28256 7.62546 4.06882 7.62923C3.85509 7.633 3.64823 7.55366 3.49184 7.40793L0.741768 4.65786C0.587269 4.50317 0.500488 4.29348 0.500488 4.07485C0.500488 3.85622 0.587269 3.64653 0.741768 3.49184L3.49184 0.741768C3.64653 0.587269 3.85622 0.500488 4.07485 0.500488C4.29348 0.500488 4.50317 0.587269 4.65786 0.741768V0.741768Z"
		fill="currentColor"
	/>
</svg>
