<script>
	export let type = "button";
	export let disabled = false;
	export let func = null;
	export let className = "bg-accent hover:bg-accent-focus";
</script>

<button
	class="{className} px-4 py-3 text-primary rounded-md font-semibold inline-flex space-x-3 justify-center items-center"
	{type}
	{disabled}
	on:click={() => func && func()}
>
	<slot />
</button>
