<script>
	export let className;
</script>

<a href="/" aria-label="Home Page" class="text-white">
	<svg
		class="{className} block w-auto object-contain"
		viewBox="0 0 411 167"
		fill="none"
	>
		<path
			d="M177.24 82.9301H157V30.4275L177.24 11.3712H192.81C204.487 11.3712 223.171 26.1496 223.171 47.9283C223.171 75.6185 200.854 82.8005 189.696 82.9301H181.133V64.6514C194.367 64.6514 203.709 62.3179 203.709 47.9283C203.709 30.1942 186.063 28.8719 177.24 30.4275V82.9301Z"
			fill="currentColor"
		/>
		<path
			d="M232.45 19.7453L252.071 0L251.382 59.9401C251.512 62.792 251.523 69.8072 249.966 75.0963C248.409 80.3854 240.754 83.1895 237.9 83.708H222.447V67.7626H229.726C231.906 67.7626 232.45 66.4663 232.45 65.8181V61.5401V19.7453Z"
			fill="currentColor"
		/>
		<path
			d="M22.4617 94.3672H69.4633L50.9338 114.26H24.2695C20.2924 116.068 22.6124 119.836 24.2695 121.493L35.5679 122.398C41.1418 123.754 52.2896 130.083 52.2896 144.551C52.2896 159.018 40.238 165.348 34.2121 166.704H0.316772V148.168H29.2408C34.3025 144.912 31.3498 141.386 29.2408 140.03H22.4617C15.532 139.125 1.67259 133.248 1.67259 116.972C1.67259 100.697 15.532 95.1207 22.4617 94.3672Z"
			fill="currentColor"
		/>
		<path
			d="M56.809 114.26L76.2424 94.3672H120.984L101.551 114.26H95.2237V166.704H74.4346V114.26H56.809Z"
			fill="currentColor"
		/>
		<path
			d="M107.878 114.26L128.215 94.3672H172.505L153.976 114.26H128.215V121.493L153.976 122.398V140.03H128.215V148.168H153.976V166.704H107.878V114.26Z"
			fill="currentColor"
		/>
		<path
			d="M161.207 114.26L179.736 94.3672H224.478L205.045 114.26H198.718V166.704H177.928V114.26H161.207Z"
			fill="currentColor"
		/>
		<path
			d="M235.325 94.3672H282.778L263.797 113.808H237.584C231.257 115.164 235.325 120.589 235.776 121.041C236.228 121.493 240.748 122.398 243.911 122.398C246.442 122.398 250.69 123.603 252.498 124.206C256.716 126.467 265.152 133.158 265.152 141.838C265.152 158.837 253.703 165.498 247.979 166.704H214.083V148.62H239.844C240.296 148.62 241.471 148.529 242.556 148.168C247.104 144.318 243.555 141.569 241 140.575C241.617 140.837 241.154 141.002 235.325 140.03C227.19 138.673 214.987 133.7 214.987 116.52C214.987 102.776 228.545 96.0249 235.325 94.3672Z"
			fill="currentColor"
		/>
		<path
			d="M343.79 114.26L363.223 94.3672L388.08 118.329V94.3672H410.225V166.704L363.223 121.041V166.704H343.79V114.26Z"
			fill="currentColor"
		/>
		<circle cx="303.786" cy="130.536" r="36.1683" fill="currentColor" />
		<ellipse
			cx="304.131"
			cy="130.535"
			rx="17.223"
			ry="16.8786"
			fill="black"
		/>
	</svg>
</a>
