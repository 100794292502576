<script>
	export let className = "";
</script>

<svg class={className} viewBox="0 0 20 20" fill="none">
	<path
		d="M0.993001 0.194139L19.9058 9.51322C20.009 9.56404 20.0958 9.64272 20.1565 9.74035C20.2173 9.83798 20.2495 9.95066 20.2495 10.0656C20.2495 10.1806 20.2173 10.2933 20.1565 10.3909C20.0958 10.4886 20.009 10.5672 19.9058 10.6181L0.991769 19.9371C0.885808 19.9892 0.767118 20.0097 0.649835 19.9962C0.532553 19.9827 0.42162 19.9358 0.33025 19.861C0.238879 19.7863 0.170921 19.6868 0.13447 19.5745C0.098018 19.4622 0.0946091 19.3418 0.124649 19.2277L1.99807 12.1515C2.02844 12.037 2.09119 11.9337 2.17879 11.854C2.2664 11.7742 2.37514 11.7215 2.49199 11.702L10.9673 10.2892C11.0179 10.2808 11.0655 10.26 11.1059 10.2286C11.1463 10.1972 11.1783 10.1562 11.1989 10.1094L11.2211 10.0355C11.2326 9.96697 11.2206 9.89659 11.187 9.83578C11.1535 9.77496 11.1004 9.72726 11.0363 9.70044L10.9673 9.68073L2.44518 8.26058C2.32856 8.24086 2.22008 8.18798 2.13271 8.10826C2.04534 8.02853 1.98278 7.92534 1.9525 7.811L0.124649 0.904832C0.0942966 0.790579 0.0974697 0.670004 0.133789 0.557505C0.170109 0.445006 0.23804 0.345337 0.32947 0.270401C0.420901 0.195464 0.531968 0.148425 0.649408 0.134902C0.766848 0.121379 0.885699 0.141943 0.991769 0.194139H0.993001Z"
		fill="currentColor"
	/>
</svg>
