<script>
	export let className;
</script>

<svg class="{className} text-accent" viewBox="0 0 34 34" fill="none">
	<path
		d="M32.9011 12.1982L22.7085 10.7167L18.1522 1.47834C18.0278 1.2254 17.823 1.02064 17.5701 0.89618C16.9359 0.583015 16.1651 0.843985 15.8479 1.47834L11.2916 10.7167L1.09908 12.1982C0.81807 12.2384 0.561149 12.3709 0.364444 12.5716C0.126638 12.8161 -0.00440342 13.1449 0.000112989 13.486C0.0046294 13.827 0.144334 14.1523 0.38853 14.3904L7.76297 21.5811L6.02072 31.7349C5.97986 31.9711 6.006 32.214 6.09616 32.4361C6.18632 32.6582 6.3369 32.8506 6.53082 32.9915C6.72475 33.1323 6.95426 33.216 7.19332 33.2331C7.43239 33.2501 7.67145 33.1998 7.8834 33.0879L17.0001 28.2941L26.1168 33.0879C26.3657 33.2204 26.6547 33.2646 26.9317 33.2164C27.6302 33.0959 28.0999 32.4335 27.9794 31.7349L26.2372 21.5811L33.6116 14.3904C33.8124 14.1936 33.9448 13.9367 33.985 13.6556C34.0934 12.953 33.6036 12.3026 32.9011 12.1982Z"
		fill="currentColor"
	/>
</svg>
