<script>
	import Heading from "../components/text/Heading.svelte";
	import SubHeading from "../components/text/SubHeading.svelte";
	import RoundedButtonLink from "../components/buttons/RoundButtonLink.svelte";
	import ContactForm from "../components/forms/ContactForm.svelte";

	// Icons
	import Facebook from "../components/icons/Facebook.svelte";
	import Instagram from "../components/icons/Instagram.svelte";
	import Youtube from "../components/icons/Youtube.svelte";
	import Spotify from "../components/icons/Spotify.svelte";
	import Whatsapp from "../components/icons/Whatsapp.svelte";
	import Phone from "../components/icons/Phone.svelte";
	import Email from "../components/icons/Email.svelte";

	// Store
	import { data } from "../stores";
</script>

<section class="mt-10 xl:grid xl:grid-cols-2" data-aos="fade-in">
	<div>
		<img
			class="hidden xl:block xl:rounded-tl-lg"
			src="./images/contact.png"
			alt="Contact"
		/>
		<div
			class="flex flex-col bg-secondary-focus p-10 rounded-t-lg xl:rounded-t-none xl:rounded-bl-lg text-center xl:text-left"
		>
			<SubHeading>
				<a
					class="text-primary hover:underline"
					href={$data.links.contact}>{$data.info.contact}</a
				>
			</SubHeading>
			<SubHeading className="mt-4">
				<a class="text-primary hover:underline" href={$data.links.email}
					>{$data.info.email}</a
				>
			</SubHeading>

			<!-- Social Links -->
			<div
				class="mt-8 flex flex-wrap justify-center space-x-3 space-y-3 xl:justify-start sm:space-y-0"
			>
				<RoundedButtonLink
					route={$data.links.facebook}
					target="_blank"
					className="h-10 w-10 p-2 bg-primary text-secondary-focus mt-3 sm:mt-0"
				>
					<Facebook className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.instagram}
					target="_blank"
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Instagram className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.youtube}
					target="_blank"
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Youtube className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.spotify}
					target="_blank"
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Spotify className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.whatsapp}
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Whatsapp className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.contact_link}
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Phone className="w-full h-full" />
				</RoundedButtonLink>
				<RoundedButtonLink
					route={$data.links.email_link}
					className="h-10 w-10 p-2 bg-primary text-secondary-focus"
				>
					<Email className="w-full h-full" />
				</RoundedButtonLink>
			</div>
		</div>
	</div>
	<div
		class="bg-secondary p-10 text-primary rounded-b-lg xl:rounded-bl-none xl:xl:rounded-tr-lg xl:flex xl:flex-col xl:justify-center xl:p-8"
	>
		<Heading className="text-center xl:text-left">GET IN TOUCH</Heading>
		<ContactForm />
	</div>
</section>
