import { readable, writable } from "svelte/store";

export const routing = writable({
	routes: {
		home: "/",
		videos: "/videos",
		about: "/about",
		contact: "/contact",
		default: "/*",
	},
	// Page component
	page: null,
	path: window.location.pathname,
	toggleNav: false,
});

export const contactDetails = writable({
	name: "",
	nameError: "",
	email: "",
	emailError: "",
	phone: "",
	phoneError: "",
	message: "",
	messageError: "",
	honeypot: "",
	sending: false,
	key: "718f54b4-83d8-4cf8-91c1-53e0f52c0222",
});

export const data = readable({
	info: {
		contact: "+91-9820695887",
		email: "djstetson@hotmail.com",
	},
	links: {
		facebook: "https://www.facebook.com/djstetson",
		instagram: "https://www.instagram.com/djstetson/",
		youtube:
			"https://www.youtube.com/channel/UCcxtSPi9-tn45suc8hzIVfg/featured",
		spotify:
			"https://open.spotify.com/playlist/18BuWH0FoRImgU8aIZ8Zqr?si=kOcbDyUgR7eNbcPn-63a3A",
		whatsapp: "https://wa.me/919820695887",
		contact: "tel:+91-9820695887",
		email: "mailto:djstetson@hotmail.com",
	},
	weddings: [
		"Anushka Sharma & Virat Kohli's Wedding in Italy",
		"Sonam Kapoor & Anand Ahuja's Wedding",
		"Amit Thackeray's Wedding",
		"Devansh & Nandini' Barjatya Wedding",
		"Morani Wedding",
		"Binoy Gandhi & Niddhi Dutta's Wedding",
		"Katrina Kaif & Vicky Kaushal's Wedding",
		"Sayesha Saigal & Arya Wedding",
		"Luv Ranjan & Alisha Vaid Wedding",
		"Sharmil Segal & Aman Mehta's Wedding in Italy"
	],
	events: [
		"Filmfare Awards hosted by Karan Johar",
		"New Year's Party 2006 & 2008 in Shanghai",
		"Salman's Birthday Bash",
		"Played at Club Pacha Madrid, Spain",
		"Bollywood night 2014 in Poland",
		"Hrithik Roshan's Birthday",
		"Performed with Akon at Shahrukh Khan's Party 2010",
		"Shahrukh Khan's Birthday Bash",
		"Diwali Parties performed in Spain 2011, 2012, 2013, 2014, 2015, 2016, 2018, 2021",
	],
	parties: [
		"Shahrukh Khan",
		"Salman Khan",
		"Hrithik Roshan",
		"Priyanka Chopra",
		"Ambanis",
		"Karan Johar",
		"Yash Chopra",
		"Juhi Chawla",
		"Malaika Arora Khan",
		"Amrita Arora",
		"Chunky Pandey",
		"Neelam",
		"Kajal Anand",
		"Anil Kapoor",
		"Sanjay Kapoor",
		"Surely Goel",
		"Vikram Chatwal",
		"Vijay Mallya",
		"Nandita Mehtani",
		"Godrej",
		"Ruia's",
		"Neeta Lulla",
		"Morani's",
		"Tamanah Bhatia",
		"Vijay Verma Varma",
		"Kriti Sanon"
	],
	countries: [
		"India",
		"U.A.E",
		"China",
		"Singapore",
		"Malaysia",
		"Pakistan",
		"Sri Lanka",
		"Mauritius",
		"Thailand",
		"Turkey",
		"Bahrain",
		"Egypt",
		"Spain",
		"Italy",
		"France",
		"Portugal",
		"Poland",
		"Nigeria",
		"Hongkong",
		"Switzerland",
		"Greece",
		"Ghana"
	],
	skills: [
		"Bollywood",
		"Commercial",
		"Hip Hop",
		"R&B",
		"Punjabi",
		"Electronic Dance Music",
		"English Retro",
		"House & Lounge",
	],
	videos: [
		"https://www.youtube.com/embed/uMbbCHdfpTM?rel=0",
		"https://www.youtube.com/embed//bsEtABdZrOI?rel=0",
		"https://www.youtube.com/embed/06PV58fwnAw?rel=0",
		"https://www.youtube.com/embed/zBXq8QtUyk4?rel=0",
		"https://www.youtube.com/embed/d2UMf4zD4Gg?rel=0",
		"https://www.youtube.com/embed/oaFuMXHCar0?rel=0",
		"https://www.youtube.com/embed/YZHGLzIK0zk?rel=0",
		"https://www.youtube.com/embed/qzNMuokEXYA?rel=0",
		"https://www.youtube.com/embed/gFLqtVnpmJQ?rel=0",
		"https://www.youtube.com/embed/rybHUAOBHaA?rel=0"
	],
	redirects: {
		contact: "http://localhost:8080/contact",
	},
});
