<script>
	// Icons
	import Download from "../icons/Download.svelte";

	export let route = "";
	export let className = "bg-accent hover:bg-accent-focus";
</script>

<a
	href={route}
	target="_blank"
	class="{className} px-4 py-3 text-primary rounded-md font-semibold inline-flex space-x-3 justify-center items-center transform scale-100 transition-all hover:scale-110"
>
	<Download className="h-6 w-6" />

	<slot />
</a>
