<script>
	export let className = "";
	export let method = "get";
	export let func = null;
	export let action = "";
	export let enctype = "application/x-www-form-urlencoded";
</script>

<form
	{action}
	{method}
	class="flex flex-col space-y-8 {className}"
	{enctype}
	on:submit|preventDefault={() => func && func()}
>
	<slot />
</form>
