<script>
	import { routing } from "../../stores";
	export let route;
	export let aria_label = "";

	$: active = $routing.path === route ? true : false;

	const handleClick = (route) => {
		$routing.path = route;
		$routing.toggleNav = false;
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	};
</script>

<a
	on:click={() => handleClick(route)}
	href={route}
	aria-label={aria_label}
	class="font-display block px-4 py-4 pl-8 border-l-4 transition 
        duration-150 ease-in-out text-xl
        {active
		? 'border-accent hover:border-accent focus:border-accent-focus bg-accent text-primary'
		: 'border-transparent hover:border-secondary-focus focus:border-x-secondary-focus text-secondary'}"
>
	<slot />
</a>
