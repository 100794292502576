<script>
	export let className;
</script>

<svg
	class={className}
	viewBox="0 0 32 32"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_24_931)">
		<path
			d="M31.3334 8.67594C31.1522 8.02958 30.7992 7.4444 30.312 6.98261C29.8111 6.50661 29.1971 6.16612 28.528 5.99327C26.024 5.33327 15.992 5.33327 15.992 5.33327C11.8098 5.28569 7.6286 5.49497 3.47203 5.95994C2.80295 6.14556 2.19011 6.49365 1.68803 6.97327C1.1947 7.44794 0.837363 8.03327 0.650696 8.67461C0.202287 11.0903 -0.0155812 13.5431 2.9454e-05 15.9999C-0.0159705 18.4546 0.201363 20.9066 0.650696 23.3253C0.833363 23.9639 1.18936 24.5466 1.68403 25.0173C2.1787 25.4879 2.7947 25.8279 3.47203 26.0079C6.00936 26.6666 15.992 26.6666 15.992 26.6666C20.1796 26.7143 24.3661 26.505 28.528 26.0399C29.1971 25.8671 29.8111 25.5266 30.312 25.0506C30.8054 24.5799 31.156 23.9946 31.332 23.3573C31.7922 20.9425 32.0159 18.4887 32 16.0306C32.0346 13.5621 31.8112 11.0967 31.3334 8.67461V8.67594ZM12.8027 20.5653V11.4359L21.1494 16.0013L12.8027 20.5653Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_24_931">
			<rect width="32" height="32" fill="none" />
		</clipPath>
	</defs>
</svg>
