<script>
	// Components
	import DownloadButton from "../components/buttons/DownloadButton.svelte";
	import RoundedButtonLink from "../components/buttons/RoundButtonLink.svelte";
	import Heading from "../components/text/Heading.svelte";
	import List from "../components/lists/List.svelte";
	import Globe from "../components/Globe.svelte";

	// Store
	import { data } from "../stores";

	// Icons
	import Facebook from "../components/icons/Facebook.svelte";
	import Instagram from "../components/icons/Instagram.svelte";
	import Youtube from "../components/icons/Youtube.svelte";
	import Spotify from "../components/icons/Spotify.svelte";
	import Whatsapp from "../components/icons/Whatsapp.svelte";
	import Phone from "../components/icons/Phone.svelte";
	import Email from "../components/icons/Email.svelte";
</script>

<!-- Banner Section -->
<section class="mt-8" data-aos="fade-in">
	<!-- Banner -->
	<img class="w-full" src="/images/banner.png" alt="Main Banner" />

	<!-- Download Buttons -->
	<!-- <div class="flex justify-center" data-aos="zoom-in">
		<div
			class="inline-flex flex-col sm:flex sm:flex-row sm:justify-center mt-4 sm:space-x-4 space-y-4 sm:space-y-0"
		>
			<DownloadButton
				className="bg-accent hover:bg-accent-focus text-xl"
				route="/documents/dj-stetson-portfolio.pdf"
			>
				Portfolio
			</DownloadButton>
			<DownloadButton
				route="/documents/dj-stetson-technical-rider.pdf"
				className="bg-accent-secondary hover:bg-accent-secondary-focus text-xl"
			>
				Technical Rider
			</DownloadButton>
		</div>
	</div> -->

	<!-- Social Links -->
	<!-- <div
		class="mt-6 flex flex-wrap justify-center items-center space-x-3 space-y-3"
		data-aos="zoom-in"
	>
		<RoundedButtonLink
			animationMultiplier="6"
			route={$data.links.facebook}
			target="_blank"
			className="h-10 w-10 bg-secondary text-primary mt-3"
		>
			<Facebook className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="7"
			route={$data.links.instagram}
			target="_blank"
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Instagram className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="8"
			route={$data.links.youtube}
			target="_blank"
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Youtube className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="9"
			route={$data.links.spotify}
			target="_blank"
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Spotify className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="10"
			route={$data.links.whatsapp}
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Whatsapp className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="11"
			route={$data.links.contact_link}
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Phone className="w-full h-full" />
		</RoundedButtonLink>
		<RoundedButtonLink
			animationMultiplier="12"
			route={$data.links.email_link}
			className="h-10 w-10 bg-secondary text-primary"
		>
			<Email className="w-full h-full" />
		</RoundedButtonLink>
	</div> -->
</section>

<!-- Awards section -->
<!-- <section class="mt-20" data-aos="zoom-in">
	<Heading className="text-center text-accent mb-4">AWARDS</Heading>
	<div
		class="flex justify-center items-center flex-col md:flex-row md:flex-wrap"
	>
		{#each $data.awards as award, i}
			<Award animationMultiplier={i}>
				<img
					class="w-1/2 mb-4"
					src="/images/wedding-sutra.png"
					alt="Wedding Sutra"
				/>
				<SubHeading className="text-primary font-semibold"
					>{award.title}</SubHeading
				>
				<SubHeading className="text-primary">{award.year}</SubHeading>
			</Award>
		{/each}
	</div>
</section> -->

<section class="mt-10" data-aos="zoom-in">
	<Heading className="text-center text-accent mb-4 md:mb-8">AWARDS</Heading>
	<img
		class="w-full xl:w-3/4 xl:mx-auto"
		src="/images/awards.png"
		alt="Awards"
	/>
</section>

<!-- Wedding Section -->
<section
	class="mt-20 flex flex-col items-center xl:grid xl:gap-8 xl:justify-items-center xl:grid-flow-row xl:grid-rows-1 xl:grid-cols-2 xl:place-items-center"
>
	<List
		className="xl:order-2"
		heading="BIG FAT CELEBRITY WEDDINGS PERFORMED"
		items={$data.weddings}
		data_aos="fade-left"
	/>
	<img
		class="object-contain w-full xl:order-1"
		src="/images/weddings.png"
		alt="Celebrity Weddings Performed"
		data-aos="fade-right"
	/>
</section>

<!-- Events Performed Section -->
<section
	class="mt-20 flex flex-col items-center xl:grid xl:gap-8 xl:justify-items-center xl:grid-flow-row xl:grid-rows-1 xl:grid-cols-2 xl:place-items-center"
>
	<List
		className="xl:order-1"
		heading="EVENTS PERFORMED"
		items={$data.events}
		data_aos="fade-right"
	/>
	<img
		class="object-contain w-full mt-6 xl:order-2 xl:mt-0"
		src="/images/events-collage.png"
		alt="Events Performed"
		data_aos="fade-left"
	/>
</section>

<!-- High Profile Parties Section -->
<section
	class="mt-20 flex flex-col items-center xl:grid xl:gap-8 xl:justify-items-center xl:grid-flow-row xl:grid-rows-1 xl:grid-cols-2 xl:place-items-center"
>
	<List
		className="xl:order-2"
		itemContainerClassName="grid grid-cols-2"
		heading="HIGH PROFILE PARTIES"
		items={$data.parties}
		data_aos="fade-left"
	/>
	<img
		class="object-contain w-full mt-6 xl:order-1 xl:mt-0"
		src="/images/parties-collage.png"
		alt="High Profile Parties"
		data-aos="fade-right"
	/>
</section>

<!-- Countries Visited Section -->
<section
	class="mt-20 flex flex-col items-center xl:grid xl:gap-8 xl:justify-items-center xl:grid-flow-row xl:grid-rows-1 xl:grid-cols-2 xl:place-items-center"
>
	<List
		className="xl:order-1"
		itemContainerClassName="grid grid-cols-2 xl:grid-cols-3"
		heading="COUNTRIES PERFORMED AT"
		items={$data.countries}
		data_aos="fade-right"
	/>
	<Globe className="xl:order-2" data_aos="fade-left" />
</section>
