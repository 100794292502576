<script>
	export let route = "";
	export let target = "";
	export let className = "";
</script>

<a
	href={route}
	{target}
	class="rounded-full hover:cursor-pointer transform scale-100 hover:scale-125 transition  {className}"
>
	<slot />
</a>
