<script>
	export let className;
</script>

<svg
	class={className}
	viewBox="0 0 34 34"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M14.1632 19.2069C16.1058 21.1775 18.4562 22.6989 21.0494 23.6643L24.5576 20.8726C24.6616 20.801 24.7849 20.7626 24.9112 20.7626C25.0375 20.7626 25.1608 20.801 25.2649 20.8726L31.7788 25.0694C32.0261 25.2181 32.2354 25.4223 32.3903 25.6658C32.5451 25.9092 32.6412 26.1854 32.671 26.4724C32.7008 26.7594 32.6634 27.0494 32.5619 27.3195C32.4603 27.5896 32.2974 27.8324 32.0859 28.0286L29.0336 31.0437C28.5965 31.4756 28.0593 31.7925 27.4699 31.9663C26.8804 32.14 26.2572 32.1651 25.6557 32.0394C19.6556 30.8002 14.1252 27.8979 9.69651 23.6643C5.36612 19.3886 2.36951 13.9481 1.07019 8.0029C0.941829 7.41073 0.968619 6.79544 1.14796 6.21666C1.3273 5.63788 1.65311 5.11525 2.09381 4.6994L5.25772 1.64715C5.45351 1.44595 5.69228 1.29165 5.95616 1.19581C6.22003 1.09996 6.50216 1.06505 6.78144 1.0937C7.06071 1.12234 7.32989 1.21379 7.56881 1.36121C7.80774 1.50862 8.01021 1.70818 8.16108 1.94493L12.4975 8.37513C12.5723 8.4761 12.6127 8.59843 12.6127 8.72409C12.6127 8.84975 12.5723 8.97208 12.4975 9.07305L9.64068 12.5068C10.6323 15.0481 12.1771 17.3369 14.1632 19.2069Z"
		fill="currentColor"
	/>
</svg>
