<script>
	export let className;
</script>

<svg
	class={className}
	viewBox="0 0 34 34"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_24_943)">
		<path
			d="M3.70256 5.0249H29.7977C32.2596 5.0249 33.5003 6.26023 33.5003 8.77275V26.4023C33.5003 28.8939 32.2596 30.1501 29.7977 30.1501H3.70256C1.24075 30.1501 0 28.8939 0 26.4023V8.77275C0 6.26023 1.24075 5.0249 3.70256 5.0249ZM16.7403 23.0313L30.0144 11.4528C30.4871 11.034 30.8612 10.0709 30.2704 9.21244C29.6993 8.354 28.6555 8.33306 27.9662 8.8565L16.7403 16.9384L5.53415 8.8565C4.84484 8.33306 3.80103 8.354 3.22989 9.21244C2.63906 10.0709 3.01326 11.034 3.48592 11.4528L16.7403 23.0313Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_24_943">
			<rect width="33.5003" height="33.5003" fill="none" />
		</clipPath>
	</defs>
</svg>
