<script>
	export let className;
</script>

<svg
	class={className}
	viewBox="0 0 34 34"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g clip-path="url(#clip0_24_940)">
		<path
			d="M19.2566 33.5003V18.2441H24.2174L24.9548 12.2708H19.2566V8.46606C19.2566 6.74238 19.7195 5.56224 22.1039 5.56224H25.1252V0.236716C23.6552 0.073266 22.1775 -0.00565503 20.6991 0.00031489C16.3142 0.00031489 13.3036 2.77755 13.3036 7.87599V12.2596H8.37506V18.2329H13.3143V33.5003H19.2566Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_24_940">
			<rect width="33.5003" height="33.5003" fill="none" />
		</clipPath>
	</defs>
</svg>
