<script>
	export let className = "";
	export let name = "";
	export let label = "";
	export let required = true;
</script>

<label class="flex flex-col font-medium text-xl {className}" for={name}>
	<div class="inline-flex space-x-2">
		{label} :
		<span class="{required || 'hidden'} text-red-600 ml-2">*</span>
	</div>
	<slot />
</label>
