<script>
	import Star from "../icons/Star.svelte";
	import SubHeading from "../text/SubHeading.svelte";
</script>

<div class="flex space-x-4 xl:mt-4 xl:items-start">
	<Star className="w-6 h-6 hidden xl:block" />
	<SubHeading className="w-full text-center xl:text-left">
		<slot />
		<div class="my-4 w-full border-b border-gray-700 xl:hidden" />
	</SubHeading>
</div>
