<script>
	import Heading from "../text/Heading.svelte";
	import ListItem from "./ListItem.svelte";

	export let heading;
	export let items;
	export let className;
	export let itemContainerClassName = "";
	export let data_aos="";
</script>

<div class="flex flex-col xl:justify-center {className}" data-aos={data_aos}>
	{#if heading !== ""}
		<Heading className="text-accent text-center mb-6 xl:text-left">
			{heading}
		</Heading>
	{/if}

	<div class={itemContainerClassName}>
		{#each items as item}
			<ListItem>{item}</ListItem>
		{/each}
	</div>
</div>
