<script>
	import Heading from "../components/text/Heading.svelte";
	import SubHeading from "../components/text/SubHeading.svelte";
	import List from "../components/lists/List.svelte";

	// Store
	import { data } from "../stores";
</script>

<section
	class="xl:grid xl:grid-flow-row xl:grid-rows-1 xl:grid-cols-2 xl:gap-8 mt-10"
>
	<div class="text-center xl:text-left" data-aos="fade-right">
		<Heading className="text-accent">ABOUT</Heading>
		<SubHeading className="my-4 font-display"
			>DEEJAYING FOR MORE THAN A DECADE</SubHeading
		>
		<SubHeading>
			<b class="text-accent font-medium">DJ Stetson</b> learnt the art of Deejaying
			for more than a decade. Stetson has followed wherever the music taken
			him and today, is one of the few who has transformed his passion into
			his career and identity. He has mastered the art of mixing and creating
			bass thumping tracks in all genres like :
		</SubHeading>

		<List className="mt-6" heading="" items={$data.skills} />

		<SubHeading className="mt-6 xl:mt-10">
			It is this universal appeal and understanding of music, which helped
			him establish his fan base around the globe.
		</SubHeading>
	</div>
	<img
		class="w-full mt-10 xl:mt-0"
		src="./images/about.png"
		alt="About Stetson"
		data-aos="fade-left"
	/>
</section>
