<script>
	import { routing } from "../../stores";
	import Logo from "../../components/icons/Logo.svelte";
	import Hamburger from "../../components/icons/Hamburger.svelte";
	import DesktopLink from "./DesktopLink.svelte";
	import MobileLink from "./MobileLink.svelte";
	import RoundedButtonLink from "../buttons/RoundButtonLink.svelte";
	import Facebook from "../icons/Facebook.svelte";
	import Instagram from "../icons/Instagram.svelte";
	import Youtube from "../icons/Youtube.svelte";

	import { data } from "../../stores";

	const toggleNav = () => {
		$routing.toggleNav = !$routing.toggleNav;
	};
</script>

<header>
	<!-- Desktop Navigation -->
	<nav class="w-full">
		<div class="bg-primary-focus py-2 inset-x-0 fixed z-50">
			<div class="container flex justify-between h-16">
				<div class="w-full relative">
					<div
						class="absolute left-0 top-1/2 transform -translate-y-1/2"
					>
						<div class="inline-flex space-x-2" data-aos="zoom-in">
							<RoundedButtonLink
								route={$data.links.facebook}
								target="_blank"
								className="h-6 w-6 p-1 bg-secondary text-primary"
							>
								<Facebook className="w-full h-full" />
							</RoundedButtonLink>
							<RoundedButtonLink
								route={$data.links.instagram}
								target="_blank"
								className="h-6 w-6 p-1 bg-secondary text-primary"
							>
								<Instagram className="w-full h-full" />
							</RoundedButtonLink>
						</div>
					</div>

					<div
						class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
					>
						<Logo className="h-12" />
					</div>

					<!-- <div class="hidden space-x-6 ml-auto md:flex">
						<DesktopLink
							aria_label="Home Page"
							route={$routing.routes.home}>HOME</DesktopLink
						>
						<DesktopLink
							aria_label="Videos Page"
							route={$routing.routes.videos}>VIDEOS</DesktopLink
						>
						<DesktopLink
							aria_label="About Page"
							route={$routing.routes.about}>ABOUT</DesktopLink
						>
						<DesktopLink
							aria_label="Contact Page"
							route={$routing.routes.contact}>CONTACT</DesktopLink
						>
					</div> -->
					<!-- Hamburger -->
					<div
						class="absolute right-0 top-1/2 transform -translate-y-1/2"
					>
						<button
							aria_label="Toggle Navigation"
							aria-label="toggle navigation"
							class="inline-flex items-center justify-center p-2 rounded-md text-secondary hover:text-secondary-focus focus:text-secondary-focus transition duration-150 ease-in-out"
							on:click={() => toggleNav()}
						>
							<Hamburger className="h-8 w-8" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</nav>

	<!-- Mobile Navigation -->
	<div
		style="top: 80px;"
		class="{$routing.toggleNav
			? 'right-0'
			: '-right-full'} fixed z-40 transform transition-all bottom-0 min-w-max w-1/2 h-full bg-primary-focus pt-8"
	>
		<div class="pb-2 space-y-8">
			<MobileLink aria_label="Home Page" route={$routing.routes.home}
				>HOME</MobileLink
			>
			<MobileLink aria_label="Videos Page" route={$routing.routes.videos}
				>VIDEOS</MobileLink
			>
			<MobileLink aria_label="About Page" route={$routing.routes.about}
				>ABOUT</MobileLink
			>
			<MobileLink
				aria_label="Contact Page"
				route={$routing.routes.contact}>CONTACT</MobileLink
			>
		</div>
	</div>
</header>
