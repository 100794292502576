<script>
	import { toast } from "@zerodevx/svelte-toast";

	import Form from "./Form.svelte";
	import FormGroup from "./FormGroup.svelte";
	import Input from "./Input.svelte";
	import Button from "../buttons/Button.svelte";
	import TextArea from "./TextArea.svelte";

	import Send from "../icons/Send.svelte";
	import Reset from "../icons/Reset.svelte";

	import { contactDetails } from "../../stores";

	const resetForm = () => {
		$contactDetails = {
			name: "",
			nameError: "",
			email: "",
			emailError: "",
			phone: "",
			phoneError: "",
			message: "",
			messageError: "",
			honeypot: "",
			sending: false,
			key: $contactDetails.key,
		};
	};

	const submitForm = async () => {
		if (
			!validateName() ||
			!validatePhone() ||
			!validateEmail() ||
			!validateMessage()
		) {
			return false;
		}

		try {
			$contactDetails.sending = true;

			const submit = await fetch("https://api.staticforms.xyz/submit", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					accessKey: $contactDetails.key,
					name: $contactDetails.name,
					email: $contactDetails.email,
					phone: $contactDetails.phone,
					message: $contactDetails.message,
					honeypot: $contactDetails.honeypot,
				}),
			});

			const data = await submit.json();
			if (data && data.success) {
				toast.push("Message sent successfully!", {
					pausable: true,
					theme: {
						"--toastBackground": "#48BB78",
						"--toastBarBackground": "#2F855A",
					},
				});
			} else {
				throw err;
			}

			resetForm();
		} catch (error) {
			toast.push(
				"Oops! There was an error sending your message. Please try again.",
				{
					pausable: true,
					theme: {
						"--toastBackground": "#F56565",
						"--toastBarBackground": "#C53030",
					},
				}
			);
		}
	};

	function validateName() {
		$contactDetails.nameError = "";

		if ($contactDetails.name.length == 0) {
			$contactDetails.nameError = "Name is required";
			return false;
		}

		if (!$contactDetails.name.match(/^[a-z ,.'-]+$/i)) {
			$contactDetails.nameError = "Please enter a valid name";
			return false;
		}

		return true;
	}

	function validatePhone() {
		$contactDetails.phoneError = "";

		if ($contactDetails.phone.length == 0) {
			return true;
		}

		if ($contactDetails.phone.length < 10) {
			$contactDetails.phoneError =
				"Phone number must have atleast 10 digits";
			return false;
		}

		if (!$contactDetails.phone.match(/^(?=.*[0-9])[- +()0-9]+$/)) {
			$contactDetails.phoneError = "Please enter a valid phone number";
			return false;
		}

		return true;
	}

	function validateEmail() {
		$contactDetails.emailError = "";

		if ($contactDetails.email.length == 0) {
			$contactDetails.emailError = "Email is required";
			return false;
		}

		if (
			!$contactDetails.email.match(
				/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/
			)
		) {
			$contactDetails.emailError = "Please enter a valid email address";
			return false;
		}

		return true;
	}

	function validateMessage() {
		$contactDetails.messageError = "";

		if ($contactDetails.message.length == 0) {
			$contactDetails.messageError = "Message is required";
			return false;
		}

		return true;
	}
</script>

<Form className="mt-8" func={submitForm} enctype="application/json">
	<input
		type="text"
		name="honeypot"
		bind:value={$contactDetails.honeypot}
		style="display: none;"
	/>
	<FormGroup label="Name" name="name">
		<Input
			name="name"
			bind:value={$contactDetails.name}
			bind:error={$contactDetails.nameError}
		/>
	</FormGroup>
	<FormGroup label="Email" name="email">
		<Input
			name="email"
			type="email"
			bind:value={$contactDetails.email}
			bind:error={$contactDetails.emailError}
		/>
	</FormGroup>
	<FormGroup label="Phone" name="phone" required={false}>
		<Input
			name="phone"
			type="tel"
			required={false}
			bind:value={$contactDetails.phone}
			bind:error={$contactDetails.phoneError}
		/>
	</FormGroup>
	<FormGroup label="Message" name="message">
		<TextArea
			rows="3"
			name="message"
			bind:value={$contactDetails.message}
			bind:error={$contactDetails.messageError}
		/>
	</FormGroup>
	<div
		class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between sm:items-center"
	>
		<Button
			type="submit"
			className="sm:w-max bg-accent hover:bg-accent-focus text-xl"
			bind:disabled={$contactDetails.sending}
		>
			{#if $contactDetails.sending}
				SENDING...
			{:else}
				SEND MESSAGE
			{/if}
			<Send className="w-4 h-4 ml-4" />
		</Button>
		<Button
			type="reset"
			func={resetForm}
			className="sm:w-max bg-accent-secondary hover:bg-accent-secondary-focus text-xl"
		>
			RESET
			<Reset className="w-4 h-4 ml-4" />
		</Button>
	</div>
</Form>
