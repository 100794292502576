<script>
	export let route = "";
	export let target = "";
	export let aria_label = "";
</script>

<a
	href={route}
	{target}
	rel="noopener"
	aria-label={aria_label}
	class="text-secondary-focus hover:text-secondary hover:underline mt-1"
	><slot /></a
>
