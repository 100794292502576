<script>
	import { routing } from "../../stores";
	export let className;
</script>

<svg class={className} stroke="currentColor" fill="none" viewBox="0 0 24 24">
	<path
		class={$routing.toggleNav ? "hidden" : "inline-flex"}
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="2.5"
		d="M4 6h16M4 12h16M4 18h16"
	/>
	<path
		class={$routing.toggleNav ? "inline-flex" : "hidden"}
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="2.5"
		d="M6 18L18 6M6 6l12 12"
	/>
</svg>
