<script>
	export let type = "text";
	export let placeholder = "";
	export let required = true;
	export let name = "";
	export let value = "";
	export let className = "";
	export let error = "";

	const handleInput = (e) => {
		// in here, you can switch on type and implement
		// whatever behaviour you need
		value = type.match(/^(number|range)$/)
			? +e.target.value
			: e.target.value;
	};
</script>

<input
	class="w-full border-b  {error.length > 0
		? 'border-red-500   focus:border-red-600'
		: 'border-gray-500 focus:border-primary'} focus:border-b-2 py-2 focus:outline-none {className}"
	id={name}
	{name}
	{type}
	{placeholder}
	{required}
	{value}
	on:input={handleInput}
/>
{#if error.length > 0}
	<span class="text-red-500 mt-2">{error}</span>
{/if}
