<script>
	import Heading from "../components/text/Heading.svelte";
	import Spinner from "../components/icons/Spinner.svelte";
	import { data } from "../stores";
</script>

<section class="mt-10">
	<Heading className="text-accent text-center">Video Gallery</Heading>
	<div class="grid gap-8 grid-cols-1 lg:grid-cols-2 mt-10">
		{#each $data.videos as videoLink, i}
			<div class="w-full h-96 rounded-lg relative" data-aos="fade-in">
				<div class="absolute inset-0 w-full h-full" id={"spinner" + i}>
					<div class="flex w-full h-full justify-center items-center">
						<Spinner className="w-8 h-8 animate-spin" />
					</div>
				</div>
				<iframe
					class="w-full h-full bg-primary rounded-lg"
					title="Youtube Video"
					src={videoLink}
					frameborder="0"
					allowfullscreen
					onload="document.getElementById('{'spinner' +
						i}').style.display='none';"
				/>
			</div>
		{/each}
	</div>
</section>
