<script>
	export let className;
</script>

<svg class={className} width="32" height="32" viewBox="0 0 32 32" fill="none">
	<g clip-path="url(#clip0_24_928)">
		<path
			d="M15.7606 10.5077C12.8659 10.5077 10.5035 12.87 10.5035 15.7649C10.5035 18.6597 12.8659 21.022 15.7606 21.022C18.6554 21.022 21.0177 18.6597 21.0177 15.7649C21.0177 12.87 18.6554 10.5077 15.7606 10.5077ZM31.5279 15.7649C31.5279 13.5878 31.5477 11.4305 31.4254 9.25745C31.3031 6.73337 30.7273 4.49325 28.8816 2.64751C27.032 0.797836 24.7959 0.225974 22.2718 0.103714C20.0949 -0.0185464 17.9376 0.00117308 15.7646 0.00117308C13.5876 0.00117308 11.4303 -0.0185464 9.25728 0.103714C6.73325 0.225974 4.49317 0.80178 2.64747 2.64751C0.797821 4.49719 0.22597 6.73337 0.103712 9.25745C-0.018546 11.4345 0.00117306 13.5918 0.00117306 15.7649C0.00117306 17.9379 -0.018546 20.0992 0.103712 22.2722C0.22597 24.7963 0.801765 27.0365 2.64747 28.8822C4.49711 30.7319 6.73325 31.3037 9.25728 31.426C11.4343 31.5482 13.5915 31.5285 15.7646 31.5285C17.9415 31.5285 20.0988 31.5482 22.2718 31.426C24.7959 31.3037 27.0359 30.7279 28.8816 28.8822C30.7313 27.0325 31.3031 24.7963 31.4254 22.2722C31.5516 20.0992 31.5279 17.9419 31.5279 15.7649V15.7649ZM15.7606 23.8537C11.2844 23.8537 7.67187 20.2412 7.67187 15.7649C7.67187 11.2885 11.2844 7.67596 15.7606 7.67596C20.2368 7.67596 23.8494 11.2885 23.8494 15.7649C23.8494 20.2412 20.2368 23.8537 15.7606 23.8537ZM24.1806 9.23379C23.1355 9.23379 22.2916 8.3898 22.2916 7.34467C22.2916 6.29954 23.1355 5.45556 24.1806 5.45556C25.2257 5.45556 26.0697 6.29954 26.0697 7.34467C26.07 7.59284 26.0214 7.83864 25.9266 8.06797C25.8317 8.29731 25.6926 8.50569 25.5171 8.68117C25.3416 8.85666 25.1333 8.9958 24.9039 9.09062C24.6746 9.18545 24.4288 9.2341 24.1806 9.23379V9.23379Z"
			fill="currentColor"
		/>
	</g>
	<defs>
		<clipPath id="clip0_24_928">
			<rect width="31.5297" height="31.5297" fill="none" />
		</clipPath>
	</defs>
</svg>
