<script>
	export let className;
</script>

<svg class={className} viewBox="0 0 53 53" fill="none">
	<path
		d="M11.5937 43.0625H41.4062C42.0357 43.0627 42.6416 43.3018 43.1015 43.7315C43.5615 44.1613 43.8412 44.7495 43.8841 45.3775C43.927 46.0055 43.7299 46.6264 43.3327 47.1147C42.9354 47.603 42.3677 47.9223 41.7441 48.0081L41.4062 48.0312H11.5937C10.9643 48.0311 10.3584 47.7919 9.89841 47.3622C9.43847 46.9325 9.15879 46.3442 9.11589 45.7162C9.07299 45.0882 9.27006 44.4674 9.66728 43.9791C10.0645 43.4908 10.6323 43.1715 11.2558 43.0857L11.5937 43.0625H41.4062H11.5937ZM26.1621 3.33569L26.5 3.3125C27.1003 3.31253 27.6804 3.52994 28.1328 3.92454C28.5853 4.31914 28.8795 4.86423 28.9612 5.459L28.9843 5.79688V31.2634L36.454 23.797C36.8753 23.3759 37.4338 23.1201 38.0279 23.0764C38.6219 23.0326 39.2119 23.2037 39.6903 23.5585L39.9686 23.797C40.3897 24.2183 40.6455 24.7768 40.6892 25.3708C40.733 25.9649 40.5619 26.5549 40.2071 27.0333L39.9686 27.3116L28.2556 39.0212C27.8349 39.442 27.2773 39.6978 26.684 39.7422C26.0907 39.7866 25.5012 39.6166 25.0226 39.2631L24.7443 39.0212L13.0313 27.3116C12.5868 26.8683 12.3266 26.2731 12.303 25.6458C12.2794 25.0185 12.4942 24.4055 12.9042 23.93C13.3142 23.4546 13.8889 23.152 14.5129 23.0831C15.1369 23.0142 15.7638 23.184 16.2677 23.5585L16.5459 23.797L24.0156 31.27V5.79688C24.0156 5.19653 24.233 4.61649 24.6276 4.16404C25.0222 3.71159 25.5673 3.41733 26.1621 3.33569L26.5 3.3125L26.1621 3.33569Z"
		fill="currentColor"
	/>
</svg>
