<script>
	export let rows = "1";
	export let required = true;
	export let name = "";
	export let value = "";
	export let className = "";
	export let error = "";

	const handleInput = (e) => {
		value = e.target.value;
	};
</script>

<textarea
	{rows}
	{required}
	{name}
	{value}
	class="w-full border-b  {error.length > 0
		? 'border-red-500   focus:border-red-600'
		: 'border-gray-500 focus:border-primary'} focus:border-b-2 py-2 focus:outline-none {className}"
	on:input={handleInput}
/>

{#if error.length > 0}
	<span class="text-red-500 mt-2">{error}</span>
{/if}
