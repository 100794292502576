<script>
	// import { onMount, onDestroy } from "svelte";
	// import createGlobe from "cobe";

	// var globe;

	export let className = "";
	export let data_aos = "";

	// onMount(async () => {
	// 	let phi = 0;
	// 	let canvas = document.querySelector("#cobe");

	// 	globe = createGlobe(canvas, {
	// 		devicePixelRatio: 2,
	// 		width: 1000,
	// 		height: 1000,
	// 		phi: 0,
	// 		theta: 0,
	// 		dark: 1,
	// 		diffuse: 1.2,
	// 		mapSamples: 16000,
	// 		mapBrightness: 12,
	// 		baseColor: [0.3, 0.3, 0.3],
	// 		markerColor: [0.09, 1.86, 2.26],
	// 		glowColor: [2.5, 2, 0.9],
	// 		markers: [
	// 			// longitude latitude
	// 			{
	// 				location: [20.92, 77.76],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [25.24, 55.74],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [36.39, 103.65],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [1.34, 103.85],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [5.5, 101.86],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [30.05, 69.13],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [7.57, 80.75],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [-20.21, 57.5],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [15.25, 101.1],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [39.01, 35.15],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [26.04, 50.54],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [27.01, 29.64],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [39.69, -3.33],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [43.09, 12.08],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [46.74, 2.53],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [41.13, -7.84],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [52.95, 19.05],
	// 				size: 0.08,
	// 			},
	// 			{
	// 				location: [9.7, 8.29],
	// 				size: 0.08,
	// 			},
	// 		],
	// 		onRender: (state) => {
	// 			// Called on every animation frame.
	// 			// `state` will be an empty object, return updated params.
	// 			state.phi = phi;
	// 			phi += 0.01;
	// 		},
	// 	});
	// });

	// onDestroy(() => {
	// 	globe.destroy();
	// });
</script>

<!-- <canvas
	id="cobe"
	style="width: 500px; height: 500px;"
	class={className}
	width="1000"
	height="1000"
	data-aos={data_aos}
/> -->

<img
	src="/gifs/globe.gif"
	alt="Globe"
	class="{className} w-full h-full"
	data-aos={data_aos}
/>
