<script>
	// Icons
	import Logo from "../icons/Logo.svelte";
	import Download from "../icons/Download.svelte";

	// Store
	import { routing } from "../../stores";
	import { data } from "../../stores";

	// Components
	import SubHeading from "../text/SubHeading.svelte";
	import FooterLink from "./FooterLink.svelte";
	import DownloadButton from "../buttons/DownloadButton.svelte";
</script>

<footer class="bg-primary-focus mt-10 py-10">
	<div class="container flex flex-col items-center">
		<Logo className="h-14 sm:h-20 lg:h-24" />

		<p class="my-4">Deejaying for more than a decade</p>

		<!-- Download Buttons -->
		<div class="inline-flex space-x-4">
			<DownloadButton route="/documents/dj-stetson-portfolio.pdf">
				Portfolio
			</DownloadButton>
			<DownloadButton
				route="/documents/dj-stetson-technical-rider.pdf"
				className="bg-accent-secondary hover:bg-accent-secondary-focus"
			>
				Technical Rider
			</DownloadButton>
		</div>
	</div>
	<div class="border-b border-gray-700 container my-8" />
	<div class="container">
		<!-- Links -->
		<div
			class="flex flex-col items-center text-center mt-10 sm:grid sm:grid-cols-3 sm:grid-rows-1 sm:place-items-start sm:justify-items-center sm:text-left"
		>
			<div class="flex flex-col">
				<SubHeading className="font-display text-accent"
					>Navigation</SubHeading
				>
				<FooterLink aria_label="Home Page" route={$routing.routes.home}
					>Home</FooterLink
				>
				<FooterLink
					aria_label="Videos Page"
					route={$routing.routes.videos}>Vdeos</FooterLink
				>
				<FooterLink
					aria_label="About Page"
					route={$routing.routes.about}>About</FooterLink
				>
				<FooterLink
					aria_label="Contact Page"
					route={$routing.routes.contact}>Contact</FooterLink
				>
			</div>
			<div class="flex flex-col my-8 sm:my-0">
				<SubHeading className="font-display text-accent"
					>Socials</SubHeading
				>
				<FooterLink
					aria_label="Facebook Link"
					route={$data.links.facebook}
					target="_blank">Facebook</FooterLink
				>
				<FooterLink
					aria_label="Instagram Link"
					route={$data.links.instagram}
					target="_blank">Instagram</FooterLink
				>
				<FooterLink
					aria_label="Youtube Link"
					route={$data.links.youtube}
					target="_blank">Youtube</FooterLink
				>
				<FooterLink
					aria_label="Spotify Link"
					route={$data.links.spotify}
					target="_blank">Spotify</FooterLink
				>
				<FooterLink
					aria_label="WhatsApp Link"
					route={$data.links.whatsapp}>WhatsApp</FooterLink
				>
			</div>

			<div class="flex flex-col">
				<SubHeading className="font-display text-accent"
					>Contact</SubHeading
				>
				<FooterLink
					aria_label="Contact Number"
					route={$data.links.contact}
					>{$data.info.contact}
				</FooterLink>
				<FooterLink aria_label="Contact Email" route={$data.links.email}
					>{$data.info.email}</FooterLink
				>
			</div>
		</div>
		<p class="mt-10 text-center">&copy; 2022 &#9679; All Rights Reserved</p>
		<p class="mt-4 text-center">
			Powered By <a
				class="text-blue-400 hover:underline hover:text-blue-500 font-medium"
				href="https://solustem.com/"
				rel="noopener"
				aria_label="Solustem Link">Solustem</a
			>
		</p>
	</div>
</footer>
